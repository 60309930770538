import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["type", "name", "id", "placeholder", "label", "className", "dataHook", "onBlur"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '@segment/matcha/components/Input';
import companyNameRegex from '@segment/utils/regexes/companyName';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var companyNameMessages = {
  required: "Please provide your company name.",
  invalid: "Please provide a valid company name: max 60 characters, no special characters."
};

/**
 * Company name input
 */
export var CompanyNameField = function CompanyNameField(_ref) {
  var _errors$name;

  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "text" : _ref$type,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? "company" : _ref$name,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? "company" : _ref$id,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "Company name" : _ref$placeholder,
      label = _ref.label,
      className = _ref.className,
      dataHook = _ref.dataHook,
      onBlur = _ref.onBlur,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register,
      trigger = _useFormContext.trigger,
      setError = _useFormContext.setError,
      errors = _useFormContext.formState.errors;

  var _useState = useState(false),
      valid = _useState[0],
      setValid = _useState[1];

  var handleValidation = function handleValidation(value) {
    setValid(companyNameRegex.test(value));

    if (!valid) {
      setError(name, {
        message: companyNameMessages.invalid,
        type: "validate"
      });
      if (onBlur && Object.hasOwn(errors, name)) onBlur(errors);
    }
  };

  return _jsx(Input, _objectSpread(_objectSpread({
    className: className,
    id: id,
    placeholder: placeholder,
    label: label,
    type: type,
    "aria-label": placeholder,
    error: Boolean(errors[name]),
    showValidState: valid && Boolean(!errors[name]),
    errorText: (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
    expanded: true,
    "data-hook": dataHook,
    onAutoFill: function onAutoFill(value) {
      trigger("name");
      setValid(companyNameRegex.test(value));
    }
  }, register(name, {
    required: companyNameMessages.required,
    pattern: {
      value: companyNameRegex,
      message: companyNameMessages.invalid
    },
    onBlur: function onBlur(e) {
      trigger(name);
      handleValidation(e.target.value);
    }
  })), rest));
};