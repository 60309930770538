import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["type", "name", "id", "placeholder", "className", "dataHook"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Input from '@segment/matcha/components/Input';
import nameRegex from '@segment/utils/regexes/name';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var companyJobTitleMessages = {
  required: "Please provide your job title.",
  invalid: "Please provide a valid job title."
};
export var CompanyJobTitleField = function CompanyJobTitleField(_ref) {
  var _errors$name;

  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "text" : _ref$type,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? "companyJobTitle" : _ref$name,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? "companyJobTitle" : _ref$id,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "Title (e.g. Senior Product Manager)" : _ref$placeholder,
      className = _ref.className,
      dataHook = _ref.dataHook,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register,
      trigger = _useFormContext.trigger,
      errors = _useFormContext.formState.errors;

  var _useState = useState(false),
      valid = _useState[0],
      setValid = _useState[1];

  return _jsx(Input, _objectSpread(_objectSpread({
    className: className,
    id: id,
    placeholder: placeholder,
    type: type,
    "aria-label": placeholder,
    error: Boolean(errors[name]),
    showValidState: valid && Boolean(!errors[name]),
    errorText: (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
    expanded: true,
    "data-hook": dataHook,
    onAutoFill: function onAutoFill(value) {
      setValid(nameRegex.test(value));
      trigger("companyJobTitle");
    }
  }, register(name, {
    required: companyJobTitleMessages.required,
    onBlur: function onBlur(e) {
      setValid(nameRegex.test(e.target.value));
    }
  })), rest));
};
export default CompanyJobTitleField;