import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["form", "status", "heading", "description", "buttonText", "buttonUrl", "onButtonClick", "buttonColor", "compact", "borderless", "tokens", "appearance"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useTheme } from '@emotion/react';
import { AppearanceProvider, useAppearance } from '@segment/matcha/foundations/AppearanceContext';
import { CheckIcon, ClearIcon } from '@segment/matcha/components/Icons/Common';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import { data } from './data';
import * as views from './FormSubmitAction.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var BaseFormSubmitAction = function BaseFormSubmitAction(_ref) {
  var form = _ref.form,
      status = _ref.status,
      heading = _ref.heading,
      description = _ref.description,
      buttonText = _ref.buttonText,
      buttonUrl = _ref.buttonUrl,
      onButtonClick = _ref.onButtonClick,
      _ref$buttonColor = _ref.buttonColor,
      buttonColor = _ref$buttonColor === void 0 ? "primary" : _ref$buttonColor,
      _ref$compact = _ref.compact,
      compact = _ref$compact === void 0 ? false : _ref$compact,
      _ref$borderless = _ref.borderless,
      borderless = _ref$borderless === void 0 ? false : _ref$borderless,
      tokens = _ref.tokens,
      externalAppearance = _ref.appearance,
      rest = _objectWithoutProperties(_ref, _excluded);

  var theme = useTheme();
  var appearance = useAppearance(externalAppearance);
  var localData = form && data[status][form];
  return _jsx(AppearanceProvider, {
    appearance: appearance,
    children: _jsxs(views.Wrapper, _objectSpread(_objectSpread(_objectSpread({
      borderless: borderless,
      compact: compact
    }, tokens.wrapper), rest), {}, {
      children: [_jsxs(views.Header, {
        children: [status === "success" ? _jsx(CheckIcon, {
          color: theme.palette.status.success[100]
        }) : _jsx(ClearIcon, {
          color: theme.palette.status.error[100]
        }), _jsx(views.StyledTypography, {
          variant: "h4",
          tag: "p",
          tokens: tokens.header,
          children: heading || (localData === null || localData === void 0 ? void 0 : localData.heading)
        })]
      }), _jsx(views.StyledDescription, {
        variant: "pMedium",
        tokens: tokens.description,
        children: description || (localData === null || localData === void 0 ? void 0 : localData.description)
      }), !compact && (buttonText || (localData === null || localData === void 0 ? void 0 : localData.buttonText)) && buttonText !== null && _jsx(views.StyledButton, {
        variant: "filled",
        size: "large",
        color: buttonColor,
        expanded: true,
        tag: "a",
        href: buttonUrl,
        onClick: onButtonClick,
        children: buttonText || (localData === null || localData === void 0 ? void 0 : localData.buttonText)
      })]
    }))
  });
};

export var FormSubmitAction = withDesignTokens(BaseFormSubmitAction, function () {
  return {};
}, {
  dark: function dark(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.brand.darkAcai[300]
      },
      description: {
        color: theme.palette.brand.darkAcai[300]
      }
    };
  },
  light: function light(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.brand.darkAcai[300]
      },
      description: {
        color: theme.palette.brand.darkAcai[500]
      }
    };
  },
  twilioLight: function twilioLight(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.twilio.gray[30]
      },
      description: {
        color: theme.palette.twilio.gray[100]
      }
    };
  },
  twilioDark: function twilioDark(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.neutrals.white[100]
      },
      description: {
        color: theme.palette.neutrals.white[100]
      }
    };
  },
  cdpWeek2023: function cdpWeek2023(theme) {
    return {
      wrapper: {
        borderColor: theme.palette.twilio.gray[30]
      },
      description: {
        color: theme.palette.twilio.gray[20]
      }
    };
  }
});
export default FormSubmitAction;