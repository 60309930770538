import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["after", "appearance", "before", "children", "className", "methods", "gap", "success", "error", "tokens", "keepFieldsAfterSuccess", "submitCallback"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useForm, FormProvider } from 'react-hook-form';
import { useAppearance, AppearanceProvider } from '@segment/matcha/foundations/AppearanceContext';
import * as CopyText from '@segment/matcha/recipes/CopyText';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import * as views from './Form.views';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

// Set up global form defaults
var DefaultSuccessMessage = _jsxs(_Fragment, {
  children: [_jsx(CopyText.Heading, {
    variant: "h3",
    children: "Thank you"
  }), _jsx(CopyText.Description, {
    variant: "pLarge",
    tag: "p",
    children: "Your submission was successful."
  })]
});

var BaseForm = function BaseForm(_ref) {
  var _methods$formState$er, _methods$formState$er2, _methods$formState$er3, _methods$formState$er4;

  var after = _ref.after,
      appearance = _ref.appearance,
      before = _ref.before,
      children = _ref.children,
      className = _ref.className,
      _ref$methods = _ref.methods,
      methods = _ref$methods === void 0 ? useForm({
    mode: "all"
  }) : _ref$methods,
      _ref$gap = _ref.gap,
      gap = _ref$gap === void 0 ? "medium" : _ref$gap,
      _ref$success = _ref.success,
      success = _ref$success === void 0 ? DefaultSuccessMessage : _ref$success,
      error = _ref.error,
      tokens = _ref.tokens,
      _ref$keepFieldsAfterS = _ref.keepFieldsAfterSuccess,
      keepFieldsAfterSuccess = _ref$keepFieldsAfterS === void 0 ? false : _ref$keepFieldsAfterS,
      submitCallback = _ref.submitCallback,
      rest = _objectWithoutProperties(_ref, _excluded);

  var formAppearance = useAppearance(appearance);
  return _jsx(AppearanceProvider, {
    appearance: formAppearance,
    children: _jsx(FormProvider, _objectSpread(_objectSpread({}, methods), {}, {
      children: _jsxs(views.FormWrapper, _objectSpread(_objectSpread(_objectSpread({
        className: className,
        noValidate: true,
        onSubmit: submitCallback
      }, tokens.root), rest), {}, {
        children: [(keepFieldsAfterSuccess || !methods.formState.isSubmitSuccessful) && !((_methods$formState$er = methods.formState.errors) !== null && _methods$formState$er !== void 0 && (_methods$formState$er2 = _methods$formState$er.root) !== null && _methods$formState$er2 !== void 0 && _methods$formState$er2.serverError) && _jsxs(views.FormContent, _objectSpread(_objectSpread({
          gap: gap
        }, tokens.content), {}, {
          children: [before && _jsx(views.Before, {
            children: before
          }), children, after && _jsx(views.After, {
            children: after
          })]
        })), methods.formState.isSubmitSuccessful && _jsx(views.Success, {
          children: success,
          role: "alert"
        }), (_methods$formState$er3 = methods.formState.errors) !== null && _methods$formState$er3 !== void 0 && (_methods$formState$er4 = _methods$formState$er3.root) !== null && _methods$formState$er4 !== void 0 && _methods$formState$er4.serverError ? _jsx(views.Error, {
          role: "alert",
          children: error
        }) : null]
      }))
    }))
  });
};

export var Form = withDesignTokens(BaseForm, function (_ref2) {
  var gap = _ref2.gap,
      layout = _ref2.layout;
  return {
    root: {},
    content: _objectSpread({
      gap: gap === "small" ? "32px" : "40px"
    }, layout === "column" && {
      gridAutoFlow: "column",
      gridAutoColumns: "auto max-content"
    })
  };
}, {
  dark: function dark() {
    return {};
  },
  light: function light() {
    return {};
  },
  twilioLight: function twilioLight() {
    return {};
  },
  twilioDark: function twilioDark() {
    return {};
  },
  cdpWeek2023: function cdpWeek2023() {
    return {};
  }
});