import { useCallback } from 'react';
export var scrollToAnchor = function scrollToAnchor(anchorId) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var addAnchorToUrl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var anchor = document.getElementById(anchorId);

  if (anchor) {
    var y = anchor.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({
      top: y,
      behavior: "smooth"
    });

    if (addAnchorToUrl) {
      history.pushState({}, "", "#".concat(anchorId));
    }
  }
};
/**
 * Scroll into view, given an anchor (element.id)
 *
 * @param anchorId - element id to be queried and scrolled to.
 * @param offset - offset from the top of the element to scroll to.
 * @return a function that scrolls to the anchor.
 */

var useScrollToAnchor = function useScrollToAnchor(anchorId) {
  var offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var scroll = useCallback(function () {
    scrollToAnchor(anchorId, offset);
  }, [anchorId, offset]);
  return scroll;
};

export default useScrollToAnchor;