import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["type", "name", "id", "placeholder", "autoComplete", "label", "className", "dataHook"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useFormContext } from 'react-hook-form';
import Input from '@segment/matcha/components/Input';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var addressLineMessages = {
  required: "Please provide your mailing  address.",
  invalid: function invalid() {
    var characterLimit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 90;
    return "Please provide a valid address line: max ".concat(characterLimit, " characters, no special characters.");
  }
};
export var AddressLineField = function AddressLineField(_ref) {
  var _errors$name;

  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "text" : _ref$type,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? "addressLine" : _ref$name,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? "addressLine" : _ref$id,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "Address line" : _ref$placeholder,
      _ref$autoComplete = _ref.autoComplete,
      autoComplete = _ref$autoComplete === void 0 ? "address-line1" : _ref$autoComplete,
      label = _ref.label,
      className = _ref.className,
      dataHook = _ref.dataHook,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register,
      trigger = _useFormContext.trigger,
      _useFormContext$formS = _useFormContext.formState,
      errors = _useFormContext$formS.errors,
      touchedFields = _useFormContext$formS.touchedFields;

  return _jsx(Input, _objectSpread(_objectSpread({
    className: className,
    id: id,
    placeholder: placeholder,
    label: label,
    type: type,
    "aria-label": placeholder,
    error: Boolean(errors[name]),
    showValidState: touchedFields[name] && Boolean(!errors[name]),
    errorText: (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
    expanded: true,
    "data-hook": dataHook,
    autoComplete: autoComplete,
    onAutoFill: function onAutoFill() {
      trigger(name);
    }
  }, register(name)), rest));
};
export default AddressLineField;