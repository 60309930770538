import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["type", "name", "id", "className", "dataHook", "value"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useFormContext } from 'react-hook-form';
import Input from '@segment/matcha/components/Input';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/**
 * CustomFormIdentifierField hidden input for demo forms
 */
export var CustomFormIdentifierField = function CustomFormIdentifierField(_ref) {
  var _ref$type = _ref.type,
      type = _ref$type === void 0 ? "hidden" : _ref$type,
      _ref$name = _ref.name,
      name = _ref$name === void 0 ? "customFormIdentifier" : _ref$name,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? "customFormIdentifier" : _ref$id,
      className = _ref.className,
      dataHook = _ref.dataHook,
      value = _ref.value,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register;

  return _jsx(Input, _objectSpread(_objectSpread({
    "data-testid": "custom-form-identifier-field",
    className: className,
    id: id,
    type: type,
    "data-hook": dataHook,
    value: value
  }, register(name, {})), rest));
};