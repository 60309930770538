export var data = {
  success: {
    demo: {
      heading: "Thank you",
      description: "We'll get back to you with a demo shortly. For now, you can create your workspace by clicking below:",
      buttonText: "Create your free workspace"
    },
    emailCapture: {
      heading: "Thank you",
      description: "Check your inbox for an email confirmation with a link to the report. You can also click the link below to access now:"
    }
  },
  error: {
    demo: {
      heading: "Something happened",
      description: "We are really sorry. There was an error processing your submission. Try reloading your browser, and submitting again.",
      buttonText: ""
    },
    emailCapture: {
      heading: "Something happened",
      description: "We are really sorry. There was an error processing your submission. Try reloading your browser, and submitting again.",
      buttonText: ""
    }
  }
};