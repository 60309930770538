import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["name", "id", "placeholder", "label", "className", "dataHook"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useFormContext } from 'react-hook-form';
import TextArea from '@segment/matcha/components/TextArea';
import nameRegex from '@segment/utils/regexes/name';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export var detailsOfInterestMessages = {
  required: "Please provide details of interest with Segment.",
  invalid: "Please provide details of interest with Segment."
};
export var DetailsOfInterestField = function DetailsOfInterestField(_ref) {
  var _errors$name;

  var _ref$name = _ref.name,
      name = _ref$name === void 0 ? "detailsOfInterest" : _ref$name,
      _ref$id = _ref.id,
      id = _ref$id === void 0 ? "detailsOfInterest" : _ref$id,
      _ref$placeholder = _ref.placeholder,
      placeholder = _ref$placeholder === void 0 ? "" : _ref$placeholder,
      _ref$label = _ref.label,
      label = _ref$label === void 0 ? "Details of interest with Segment *" : _ref$label,
      className = _ref.className,
      dataHook = _ref.dataHook,
      rest = _objectWithoutProperties(_ref, _excluded);

  var _useFormContext = useFormContext(),
      register = _useFormContext.register,
      errors = _useFormContext.formState.errors;

  return _jsx(_Fragment, {
    children: _jsx(TextArea, _objectSpread(_objectSpread({
      id: id,
      expanded: true,
      label: label,
      placeholder: placeholder,
      error: Boolean(errors[name]),
      errorText: (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
      maxWidth: "720px",
      rows: 3,
      "data-hook": dataHook
    }, register(name, {
      required: detailsOfInterestMessages.required,
      validate: function validate(value) {
        return nameRegex.test(value) || detailsOfInterestMessages.invalid;
      }
    })), {}, {
      className: className
    }, rest))
  });
};
export default DetailsOfInterestField;